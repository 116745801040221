import consumer from './consumer'
document.addEventListener('turbolinks:load', () => {
  let user = document.getElementById('user-id');

  if (user) {
    let user_id = user.getAttribute('data-user-id');

    consumer.subscriptions.create({ channel: 'OrderChannel', user_id: user_id }, {
      received(data) {
        const kind = data['kind'];

        if (kind == 'progress_bar') {
            if (data['progress_bar_value']) {
              const html = `<div class='container' id='flashContainer'>
                <label for='progressBar'>Процесс обробки файлу:</label>
                <progress id='progressBar' max='100' value='${data['progress_bar_value']}'>${data['progress_bar_value']} %</progress>
              </div`
              
              $('#flash').replaceWith("<div class='container' id='flash'></div>");
              $('#flashContainer').replaceWith(html);
              $('#ordersContainer').css({top: '320px'})
            }

            if (data['progress_bar_message']) {
              const html = `<content>
                              <div class='row'>
                                <div class='col-6 m-auto'>
                                  <div class='alert alert-primary text-center alert-dismissible fade show'>
                                    ${data['progress_bar_message']}
                                  </div>          
                                </div>
                              </div>
                            </content>`


              // $('#flashContainer').replaceWith("<div class='container' id='flashContainer'></div>");
              $('#flashContainer').replaceWith(html);
              $('#ordersContainer').css({top: '320px'})

              setTimeout(function(){
                $('#flashContainer').replaceWith("<div class='container' id='flashContainer'></div>");
                $('#ordersContainer').css({top: '200px'})
              }, 5000);
            }
        } else {
            if (data['user_phone']) {
              $('#q_phones_cont').val(data['user_phone']);
              $('#submit-button').click();
            } else {
              document.getElementById('order-new-button').click();
            };
        }
      }
    });
  };
});
