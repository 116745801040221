$(document).on('turbolinks:load', function() {
  PrincipalImgUpload();
});

function PrincipalFileListItems (files) {
  var b = new ClipboardEvent('').clipboardData || new DataTransfer()
  for (var i = 0, len = files.length; i<len; i++) b.items.add(files[i])
  return b.files
}

function PrincipalImgUpload() {
  var imgWrap = '';
  var imgArray = [];
  let allFiles = []

  $('.principal_upload__inputfile').each(function () {
    $(this).on('change', function (e) {
      imgWrap = $(this).closest('.upload__box').find('.upload__img-wrap');
      var maxLength = $(this).attr('data-max_length');

      var files = e.target.files;
      var filesArr = Array.prototype.slice.call(files);
      var iterator = 0;

      let filesInput = document.forms[1].elements['principal_documents']
      allFiles = allFiles.concat(filesArr)
      console.log(allFiles.concat(filesArr))
      if (filesInput.files.length) {
        const fileListItems = new PrincipalFileListItems(allFiles)
        filesInput.files = fileListItems
      }

      filesArr.forEach(function (f, index) {

        if (imgArray.length > maxLength) {
          return false
        } else {
          var len = 0;
          for (var i = 0; i < imgArray.length; i++) {
            if (imgArray[i] !== undefined) {
              len++;
            }
          }
          if (len > maxLength) {
            return false;
          } else {
            imgArray.push(f);

            var reader = new FileReader();
            reader.onload = function (e) {
              var html = "<div class='upload__img-box ms-2'><div class='img-bg'>" + f.name + "<div class='upload__img-close'></div></div></div>";
              imgWrap.append(html);

              iterator++;
            }
            reader.readAsDataURL(f);
          }
        }
      });
    });
  });

  $('body').on('click', '.upload__img-close', function (e) {
    var fileName = $(this).parent().text().trim();

    imgArray = imgArray.filter(function (f) {
      return f.name !== fileName;
    });

    allFiles = allFiles.filter(function (f) {
      return f.name !== fileName;
    });

    const fileListItems = new PrincipalFileListItems(allFiles);
    document.forms[1].elements['principal_documents'].files = fileListItems;

    $(this).parent().parent().remove();
  });
}
